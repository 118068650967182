import {
  STICK_CTA_ATTR,
  STICK_CTA_ACTIVE_ATTR,
} from '../../../traits/stickyHeaderCta';

const SCROLL_OFFSET = 200;


export default function stickyHeaderCta() {
  const $stickyCtaHeaders = document.querySelectorAll(`[${STICK_CTA_ATTR}]`);
  const $prescreenerBlocks = document.querySelectorAll(
    `[data-tx-block^="prescreener-block-"]`,
  );
  let isPrescreenerVisible = false;



  if (!$stickyCtaHeaders.length) return;

  function setStickCtaHeadersVisibility(visible = false) {
 
    $stickyCtaHeaders.forEach(($stickyHeader) => {
      visible && !isPrescreenerVisible
        ? $stickyHeader.setAttribute(STICK_CTA_ACTIVE_ATTR, '')
        : $stickyHeader.removeAttribute(STICK_CTA_ACTIVE_ATTR);
    });
  }

  function handleScrollChange() {
    const canShowCta = window.pageYOffset > SCROLL_OFFSET;
    setStickCtaHeadersVisibility(canShowCta);
  }

  const io = new IntersectionObserver(
    (entries) => {
      isPrescreenerVisible = entries.some((entry) => entry.isIntersecting);
      handleScrollChange();
    },
    {
      root: null,
      rootMargin: '0px',
    },
  );

  document.addEventListener('scroll', handleScrollChange);
  $prescreenerBlocks.forEach(($block) => io.observe($block));

  handleScrollChange();
}
