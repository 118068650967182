import "intersection-observer";
import menu from "./menu";
import locale from "./locale";
import prescreener from "./prescreener";
import traits from "./traits";
import intlTelInput from "./phoneInput";
import actionBar from "./action-bar";

$(function () {
  menu.init();
  locale.init();
  prescreener.init();
  traits.init();
  intlTelInput.init();
  actionBar.init(); 
});
