// Block category types
export const HEADER_CATEGORY_TYPE = 'Headers';
export const CONTENT_CATEGORY_TYPE = 'Contents';
export const FOOTER_CATEGORY_TYPE = 'Footers';
export const PRESCREENER_CATEGORY_TYPE = 'Prescreeners';
export const SITE_LOCATOR_CATEGORY_TYPE = 'Site Locators';
export const HERO_CATEGORY_TYPE = 'Heroes';
export const CTA_CATEGORY_TYPE = 'CTAs';
export const TESTIMONIAL_CATEGORY_TYPE = 'Testimonials';
export const CONTACT_FORM_CATEGORY_TYPE = 'Contact Forms';
export const VIDEO_CATEGORY_TYPE = 'Videos';
export const EXTRA_CATEGORY_TYPE = 'Extras';
export const ACTION_BAR_CATEGORY_TYPE = 'Action Bars';
export const SOCIAL_SHARE_CATEGORY_TYPE = 'Social Share';

/**
 *  All Block IDs must follow the conventions of:
 *  `const <CATEGORY>_BLOCK_ID = '<category>_block_'`
 *  to avoid duplications in blocks
 *
 *  The is same Id is used as block dom id, editor id, and thumbnail image name
 **/

export const HERO_BLOCK_1 = 'hero-block-1';
export const HERO_BLOCK_2 = 'hero-block-2';
export const HERO_BLOCK_3 = 'hero-block-3';
export const HERO_BLOCK_4 = 'hero-block-4';
export const HERO_BLOCK_5 = 'hero-block-5';
export const HERO_BLOCK_6 = 'hero-block-6';
export const HERO_BLOCK_7 = 'hero-block-7';
export const HERO_BLOCK_8 = 'hero-block-8';

// Header block Ids
export const HEADER_BLOCK_1 = 'header-block-1';
export const HEADER_BLOCK_2 = 'header-block-2';
export const HEADER_BLOCK_3 = 'header-block-3';
export const HEADER_BLOCK_4 = 'header-block-4';

// Content block Ids
export const CONTENT_BLOCK_1 = 'content-block-1';
export const CONTENT_BLOCK_2 = 'content-block-2';
export const CONTENT_BLOCK_3 = 'content-block-3';
export const CONTENT_BLOCK_4 = 'content-block-4';
export const CONTENT_BLOCK_5 = 'content-block-5';
export const CONTENT_BLOCK_6 = 'content-block-6';
export const CONTENT_BLOCK_7 = 'content-block-7';
export const CONTENT_BLOCK_8 = 'content-block-8';
export const CONTENT_BLOCK_9 = 'content-block-9';
export const CONTENT_BLOCK_10 = 'content-block-10';
export const CONTENT_BLOCK_11 = 'content-block-11';
export const CONTENT_BLOCK_12 = 'content-block-12';
export const CONTENT_BLOCK_13 = 'content-block-13';
export const CONTENT_BLOCK_14 = 'content-block-14';

// Footer block Ids
export const FOOTER_BLOCK_1 = 'footer-block-1';
export const FOOTER_BLOCK_2 = 'footer-block-2';

// Prescreener Ids
export const PRESCREENER_BLOCK_1 = 'prescreener-block-1';

// Site Locator Ids
export const SITE_LOCATOR_BLOCK_1 = 'site-locator-block-1';

// CTA Ids
export const CTA_BLOCK_1 = 'cta-block-1';
export const CTA_BLOCK_2 = 'cta-block-2';

// Testimonials Ids
export const TESTIMONIAL_BLOCK_1 = 'testimonial-block-1';
export const TESTIMONIAL_BLOCK_2 = 'testimonial-block-2';

// Contact Form
export const CONTACT_FORM_BLOCK_1 = 'contact-form-block-1';

//Video Locator  ids
export const VIDEO_BLOCK_1 = 'video-block-1';
export const VIDEO_BLOCK_2 = 'video-block-2';

//Social Share Ids
export const SOCIAL_SHARE_BLOCK_1 = 'social-share-block-1';

// Extra block Ids
export const EXTRA_BLOCK_1 = 'extra-block-1';
export const ACTION_BAR_BLOCK_1 = 'action-bar-block-1';
