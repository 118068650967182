import { PRESCREENER_BLOCK_1 } from "../../constants";

export function initPrescreenerBlock($rootEl) {
  const $prescreenerShowBtn = $rootEl.querySelector(
    "[data-tx-show-prescreener]"
  );
  const $prescreenerContent = $rootEl.querySelector(
    "[data-tx-prescreener-content]"
  );
  const $prescreenerPlaceholder = $rootEl.querySelector(
    "[data-tx-prescreener-placeholder]"
  );

  $prescreenerShowBtn.addEventListener(
    "click",
    () => {
      $prescreenerPlaceholder.classList.add("hidden");
      $prescreenerContent.classList.remove("hidden");
    },
    {
      once: true,
    }
  );
}

export default function prescreenerLegacy() {
  try {
    const $prescreenerBlocks = document.querySelectorAll(
      `[data-tx-block="${PRESCREENER_BLOCK_1}"]`
    );

    $prescreenerBlocks.forEach(initPrescreenerBlock);
  } catch (error) {
    console.log(error);
  }
}
