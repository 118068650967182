import { getSwitchTraitEl } from './customSwitch';

export const STICKY_HEADER_CTA_TRAIT = 'sticky-header-cta';
export const STICKY_HEADER_CTA_TRAIT_ATTR = `data-tr-${STICKY_HEADER_CTA_TRAIT}`;
export const STICK_CTA_ATTR = 'data-tx-sticky-cta';
export const STICK_CTA_ACTIVE_ATTR = 'data-tx-sticky-cta-active';

function isTriatComponent(component) {
  const attrs = component.getAttributes() || {};
  return attrs.hasOwnProperty(STICKY_HEADER_CTA_TRAIT_ATTR);
}

export function stickyHeaderCtaDynamicTrait(editor, component) {
  if (!isTriatComponent(component)) return;

  const traits = component.get('traits');
  const traitTypes = new Set();
  traits.forEach((trait) => traitTypes.add(trait.get('type')));

  if (!traitTypes.has(STICKY_HEADER_CTA_TRAIT)) {
    component.addTrait({
      type: STICKY_HEADER_CTA_TRAIT,
      label: 'Sticky CTA',
      changeProp: 1,
    });
  }
}

export default function stickyHeaderCta(editor, pluginOptions) {
  return {
    trait: STICKY_HEADER_CTA_TRAIT,
    options: {
      noLabel: true,
      createInput({ trait, component }) {
        const self = this;
        const label = trait.get('label') || 'Sticky CTA';
        const checkboxId = `${STICKY_HEADER_CTA_TRAIT}-checkbox`;
        const $el = getSwitchTraitEl({ id: checkboxId, label });
        const defaultStickyCTA = component.get(STICKY_HEADER_CTA_TRAIT);

        if (defaultStickyCTA === undefined) {
          component.set(
            STICKY_HEADER_CTA_TRAIT,
            this.getStickyCtaState({ component }),
          );
        }

        self.$checkbox = $el.querySelector('#' + checkboxId);
        console.log('yyyy');
        return $el;
      },

      

      getStickyCtaState({ component }) {
        const [stickyCta] = component.find(`[${STICK_CTA_ATTR}]`);

        if (stickyCta) {
          const hasStickyHeader =
            stickyCta.getAttributes()[STICK_CTA_ATTR] || false;
          return JSON.parse(hasStickyHeader);
        }

        return false;
      },

      handleStickyCtaChange({ component }) {
        const stickyHeaderComponents = component.find(`[${STICK_CTA_ATTR}]`);
        if (
          Array.isArray(stickyHeaderComponents) &&
          stickyHeaderComponents.length === 1
        ) {
          const stickyCtaHeader = stickyHeaderComponents[0];
          const attrs = stickyCtaHeader.getAttributes();
          stickyCtaHeader.setAttributes({
            ...attrs,
            [STICK_CTA_ATTR]: component.get(STICKY_HEADER_CTA_TRAIT).toString(),
          });
        }
      },

      onEvent({ elInput: $el, component, event, trait }) {
        const isChecked = this.$checkbox.checked;

        component.set(STICKY_HEADER_CTA_TRAIT, isChecked);

        this.handleStickyCtaChange({ component });
      },

      onUpdate({ elInput: $el, component, trait }) {
        const hasStickyCta = component.get(STICKY_HEADER_CTA_TRAIT) || false;
        this.$checkbox.checked = hasStickyCta;
      },
    },
  };
}
