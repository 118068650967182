import {
  COUNTRY_DROPDOWN_BASIC_TYPE,
} from "../constants";
import { getToggleClasses } from "../utils";

function initCountryDropdown({
  $rootEl,
  countryOptions,
  websiteCountry,
  websiteLanguage,
  globalCountryOptions,
}) {
  const toggleClasses = getToggleClasses($rootEl);
  const showCountryMenu = () =>
    toggleClasses.forEach((cls) => $rootEl.classList.toggle(cls));
  $rootEl.innerHTML = `
        <div class="relative">
          <button class="border border-gray-200 rounded p-2 flex items-center hover:opacity-80 hover:border-gray-400" data-tx-country-toggle data-tx-expanded="false">
          <img class="w-5" alt="website country flag" src="${countryOptions
              .find((el) => el.country_code === websiteCountry)
              ?.flag || globalCountryOptions
              .find((el) => el.country_code === websiteCountry)
              ?.flag}"/>
            <span class="ml-2" data-tx-label><span class="dropdown_label">${websiteCountry.toUpperCase()} - </span>${websiteLanguage.toUpperCase()}</span>
            <span class="dropdown_label_arrow flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 w-4 h-4 p-px inline-block align-text-top stroke-current transform transition-transform duration-300 ease-out" width="16" height="16" data-tx-country-toggle-icon fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
          </button>
          <div class="hidden absolute bg-white text-gray-800 top-full mt-4 py-2 flex flex-col shadow-lg rounded items-stretch country-list-wrap" data-tx-country-list >
            <div class="scrollable-country-div" >
            ${countryOptions
              .map((el) => {
                return `
                <div class="flex items-start">
                  <div class="ml-2 mt-3" style="min-width:15px">
                    <svg style="display:${websiteCountry === el.country_code && websiteLanguage === el.language_code? "block": "none"}" width="15" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.375 5.75977L1.11523 3.5L0.353516 4.26172L3.375 7.2832L9.875 0.783203L9.11328 0.0214844L3.375 5.75977Z" fill="#007F59"/>
                    </svg> 
                  </div> 
                  <button class="px-2 py-2 flex justify-between w-full hover:opacity-85 hover:bg-gray-100" data-tx-country-sub-toggle data-tx-link=${el.link} data-tx-expanded="false">
                    <div class="pointer-events-none flex items-start">
                      <img class="w-5 mt-1" alt="website country flag" src="${el.flag}"/>
                      <span data-tx-label class="pointer-events-none ml-2 text-left overflow-hidden country_name"><span class="dropdown_country_name">${el.country} - </span>${el.language.charAt(0).toUpperCase() + el.language.slice(1)}</span>
                    </div>
                  </button>
                </div>`;
              })
              .join("\n")}
            </div>
            ${globalCountryOptions?.length ? `<hr/>` : ""}
            <div class="scrollable-country-div">
            ${globalCountryOptions
              .map((el) => {
                return `
                <div class="flex items-start">
                  <div class="ml-2 mt-3" style="min-width:15px">
                    <svg style="display:${websiteCountry === el.country_code && websiteLanguage === el.language_code? "block": "none"}" width="15" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.375 5.75977L1.11523 3.5L0.353516 4.26172L3.375 7.2832L9.875 0.783203L9.11328 0.0214844L3.375 5.75977Z" fill="#007F59"/>
                    </svg> 
                  </div> 
                  <button class="px-2 py-2 flex justify-between w-full hover:opacity-85 hover:bg-gray-100" data-tx-country-sub-toggle data-tx-link=${el.link} data-tx-expanded="false">
                    <div class="pointer-events-none flex items-start">
                      <img class="w-5 mt-1" alt="website country flag" src="${el.flag}"/>
                      <span data-tx-label class="pointer-events-none ml-2 text-left overflow-hidden country_name"><span class="dropdown_country_name">${el.country} - </span>${el.language.charAt(0).toUpperCase() + el.language.slice(1)}</span>
                    </div>
                  </button>
                </div>`;
              })
              .join("\n")}
            </div>
          </div>
        </div>
      `;

  const $countryMenuToggle = $rootEl.querySelector("[data-tx-country-toggle]");
  const $countrySubMenuToggle = $rootEl.querySelectorAll(
    "[data-tx-country-sub-toggle]"
  );
  const $countryList = $rootEl.querySelector("[data-tx-country-list]");
  // const $countrySubList = $rootEl.querySelector("[data-tx-country-sub-list]");

  const showMenuDropdown = () => {
    $countryMenuToggle.dataset.txExpanded = true;
    $countryList.classList.remove("hidden");
  };



  const hideMenuDropdown = () => {
    $countryMenuToggle.dataset.txExpanded = false;
    $countryList.classList.add("hidden");
    };


  const toggleMenuDropdown = () => {
    const isExpanded = JSON.parse($countryMenuToggle.dataset.txExpanded);

    isExpanded ? hideMenuDropdown() : showMenuDropdown();
  };

  const clickAwayListener = (e) => {
    if (!$rootEl.contains(e.target)) {
      hideMenuDropdown();
    }
  };

  const switchWebsiteCountry = (pathname) => {
    const { hostname, protocol, port } = location;
    const portt = port ? `:${port}` : "";
    const websiteUrl = `${protocol}//${hostname}${portt}${pathname}`;
    location.href = websiteUrl;
  };

  $countryMenuToggle.addEventListener("click", toggleMenuDropdown);
  $countrySubMenuToggle.forEach((node) => {
    node.addEventListener("click", (e) => {
      let link = e.target.getAttribute('data-tx-link')
      if(link) {
        hideMenuDropdown();
        switchWebsiteCountry(link);
      }
    });
  });
  document.body.addEventListener("click", clickAwayListener);
  showCountryMenu();
}

export default function languageDropdownBasic() {
  try {
    const websiteLanguage = window.__WEBSITE_LANGUAGE__;

    const websiteCountry = window.__WEBSITE_COUNTRY__;

    const availableCountryLanguages = window.__AVAILABLE_COUNTRY_LANGUAGES__ || [];

    const globalCountryLanguages = window.__AVAILABLE_GLOBAL_COUNTRY_LANGUAGES__ || [];

    if ( (!availableCountryLanguages.length && !globalCountryLanguages.length) ||  !websiteLanguage || !websiteCountry )
      return;


    const $countryDropdownMenus = document.querySelectorAll(
      `[data-tx-country-dropdown="${COUNTRY_DROPDOWN_BASIC_TYPE}"]`
    );

    $countryDropdownMenus.forEach(($dropdownMenu) =>
      initCountryDropdown({
        $rootEl: $dropdownMenu,
        countryOptions: availableCountryLanguages,
        globalCountryOptions:globalCountryLanguages,
        websiteCountry: websiteCountry,
        websiteLanguage:websiteLanguage,
      })
    );
  } catch (error) {
    console.log("Error", error);
  }
}
