export const CUSTOM_SWITCH_TRAIT = 'custom-switch';

export function getSwitchTraitEl({ id, label, checked = false, info = null, hasInput = false, customClass="" }) {
  const $el = document.createElement('div');
  $el.className = 'custom-control custom-switch text-tertiary-light no-print';
  let infoIcon;
  let infoIconContent;

  if (info !== null){
      infoIcon = `<svg         
      xmlns="http://www.w3.org/2000/svg"
      width="1.4em"
      height="1.4em"
      viewBox="0 2 32 32"
    >
      <path
        d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S5 22.086 5 16 9.914 5 16 5zm-1 5v2h2v-2zm0 4v8h2v-8z"
        fill="currentColor"
      />
    </svg>`;
    infoIconContent = info;
  }else{
    infoIcon = '';
    infoIconContent = '';
  }

  $el.innerHTML = `
  <div class="${customClass}">
    <input type="checkbox" class="custom-control-input cursor-pointer" id="${id}" ${
    checked ? 'checked' : ''
  }>
    <label class="custom-control-label cursor-pointer" title="${infoIconContent}" for="${id}">${label} ${infoIcon}</label>
    <input id="${id}-input" placeholder="Add ${label} link" type="text" class="ml-1 col-8 ${hasInput? "" : "d-none"}">
  </div>
  `;

  return $el;
}

export default function customSwitchTrait(editor, pluginOptions) {
  const { editorState } = pluginOptions;

  return {
    trait: CUSTOM_SWITCH_TRAIT,
    options: {
      noLabel: true,
      templateInput: '',
      createInput({ trait }) {
        const self = this;
        const name = trait.get('name');
        const label = trait.get('label');
        const checkboxId = `${name}-checkbox`;

        const $el = getSwitchTraitEl({ id: checkboxId, label });

        self.$checkbox = $el.querySelector('#' + checkboxId);

        return $el;
      },

      // How to update the component on inputs changes
      onEvent({ elInput: $el, component, event, trait }) {
        const checked = this.$checkbox.checked;
        const name = trait.get('name');
        const changeProp = trait.get('changeProp');

        changeProp
          ? component.set(name, checked)
          : component.addAttributes({ [name]: checked });
      },

      // How to update inputs on component changes
      onUpdate({ elInput: $el, component, trait }) {
        const name = trait.get('name');
        const changeProp = trait.get('changeProp');
        const checked = changeProp
          ? component.get(name)
          : component.getAttributes()[name];

        this.$checkbox.checked = checked;
      },
    },
  };
}
