import { HAMBURGER_MENU_TYPE } from "../constants";
import { getToggleClasses } from "../utils";
/**
 * Based on the data attributes, as in the format:
 *
 * [data-tx-menu="hamburber-menu"] - Container menu element
 *       [data-tx-open] data-tx-classes=["hidden"] - Show nav menu list items action
 *       [data-tx-close] data-tx-classes=["hidden"] - Hide nav menu list items action
 *       [data-tx-menu-list] data-tx-classes=["hidden"] - Actual nav list to be toggled
 */

export default function () {
  try {
    const $hamburgerMenus = document.querySelectorAll(
      `[data-tx-menu=${HAMBURGER_MENU_TYPE}]`
    );

    $hamburgerMenus.forEach(($menu) => {
      const $menuOpen = $menu.querySelector("[data-tx-open]");
      const $menuClose = $menu.querySelector("[data-tx-close]");
      const $menuList = $menu.querySelector("[data-tx-menu-list]");
      const $menuTogler = $menu.querySelectorAll("[data-tx-toggle-classes]");
      const openToggleClasses = getToggleClasses($menuOpen);
      const closeToggleClasses = getToggleClasses($menuClose);
      const listToggleClasses = getToggleClasses($menuList);

      function toggleMenu() {
        openToggleClasses.forEach((cls) => $menuOpen.classList.toggle(cls));
        closeToggleClasses.forEach((cls) => $menuClose.classList.toggle(cls));
        listToggleClasses.forEach((cls) => $menuList.classList.toggle(cls));
      }

      $menuList && $menuList.childNodes.forEach((el)=>{
        if(el.nodeName!='#text'){
          if(el.hasAttribute("data-tx-language-dropdown") || el.hasAttribute("data-tx-country-dropdown")){
            if(screen.width < 768){
              $menuTogler.forEach(menu => menu.style.margin = 0)
              $menuTogler[0].parentElement.insertBefore(el, $menuTogler[0])
              el.style.listStyle = "none"
              el.style.zIndex = 999
              el.style.minWidth = "5rem"
              el.style.marginLeft= "auto"
            }
            return el
          }
          else{
            el.addEventListener("click", toggleMenu);
          }
        }
      })
     
      $menuOpen && $menuOpen.addEventListener("click", toggleMenu);
      $menuClose && $menuClose.addEventListener("click", toggleMenu);
    });
  } catch (error) {
    console.log("Error", error);
  }
}
