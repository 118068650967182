export function getToggleClasses($el) {
  if (!$el) return [];

  const toggleClasses = $el.dataset.txToggleClasses;

  if (typeof toggleClasses !== 'string') return [];

  return toggleClasses
    .split(' ')
    .map((cls) => cls.trim())
    .filter(Boolean);
}
