import * as domToPdf from 'dom-to-pdf'
export default function () {
  let study_title=window.__STUDY_NAME__;
  var fileName='default-file.pdf'
  if(study_title){
    fileName = study_title.replace(/\s+/g, '-').toLowerCase();
  }
  const email_action = document.querySelector('[data-tx-email]')

    const website=document.getElementById("study_website");
    const download_action = document.querySelector('[data-tx-download]')
    var options = {
      filename:     `${fileName}.pdf`,
      excludeClassNames: ['download-loading'],
    };
    if(download_action){
      download_action.addEventListener("click",function(){
        document.querySelector('.download-loading').style.display="block"

        AOS && AOS.init({
          disable:true,
        })
        domToPdf(website, options, function(pdf) {
          document.querySelector('.download-loading').style.display="none"
          window.setTimeout(()=>{
            location.reload();
          },500)         
        });
        
      })
    }
    if(email_action){
      let href_link=`mailto:?subject=${study_title}&body=Hey, check out this clinical study I found - ${window.location.href}`
      email_action.addEventListener("click",function(){
        location.assign(href_link);

      })
    }
    
  }

