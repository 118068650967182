import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";

export default () => {
  const $input = document.querySelectorAll("[data-tx-phone]:not(.hidden)");
  $input.forEach(function ($in) {
    intlTelInput($in, {
      initialCountry: "auto",
      separateDialCode: true,
      geoIpLookup: function (callback) {
        $.getJSON("https://ipapi.co/json", function (data) {
          var countryCode =
            data && data.country_code ? data.country_code : "us";
          callback(countryCode);
        });
      },
      customContainer: "w-full mt-5",
    });
  });
};
